import React from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Layout from '../../components/Layout';
import PlantPic from '../../components/PlantPic';

const Header = styled.h1`
  font-family: shrikhand;
  @media (min-width: 960px) {
    font-size: 80px;
    text-align: center;
    margin-top: 150px;
  }
  @media (max-width: 960px) {
    display: none;
    font-size: 36px;
  }
`;

const Grid = styled.div`
  display: grid;
  margin: 10px;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Card = styled(Paper)`
  && {
    display: flex;
    background: var(--color-paper);
  }
`;

const Label = styled.p`
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 36px;
    margin: 15px auto 15px auto;
  }
`;

export default function Index() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="keywords"
          content={`Learn, Alto, Plants, Tutorial, Guides, Watering, Sunlight, Light`}
        />
        <meta
          name="description"
          content={`Grow your understanding of plants and become a green thumb`}
        />
        <meta name="viewport" content="width=device.width, initial-scale=1.0" />
        <title>Learn - Alto</title>
      </Helmet>
      <Header>become a green thumb</Header>
      <div>
        <Grid>
          <Card onClick={() => navigate(`light`)}>
            <Label>
              Light <SunIcon />
            </Label>
          </Card>
          <Card onClick={() => navigate(`watering`)}>
            <Label>
              Watering <WaterIcon />
            </Label>
          </Card>
          <Card onClick={() => navigate(`humidity`)}>
            <Label>
              Humidity <HumidIcon />
            </Label>
          </Card>
          <Card onClick={() => navigate(`propagation`)}>
            <Label>
              Propagation <PropogateIcon />
            </Label>
          </Card>
          <Card onClick={() => navigate(`repotting`)}>
            <Label>
              Repotting <RepotIcon />
            </Label>
          </Card>
          <Card onClick={() => navigate(`pruning`)}>
            <Label>
              Pruning <PruneIcon />
            </Label>
          </Card>
          <Card onClick={() => navigate(`fertilizing`)}>
            <Label>
              Fertilizing <FertilizeIcon />
            </Label>
          </Card>
          <Card onClick={() => navigate(`pests`)}>
            <Label>
              Pests <PestIcon />
            </Label>
          </Card>
        </Grid>
      </div>
    </Layout>
  );
}

function SunIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="28"
      width="28"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M12 2C12.5523 2 13 2.44772 13 3V4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4V3C11 2.44772 11.4477 2 12 2ZM19.0711 4.92893C19.4616 5.31945 19.4616 5.95261 19.0711 6.34314L18.364 7.05025C17.9735 7.44077 17.3403 7.44077 16.9498 7.05025C16.5593 6.65972 16.5593 6.02656 16.9498 5.63603L17.6569 4.92893C18.0474 4.5384 18.6806 4.5384 19.0711 4.92893ZM4.92893 4.92893C5.31945 4.5384 5.95262 4.5384 6.34314 4.92893L7.05025 5.63603C7.44077 6.02656 7.44077 6.65972 7.05025 7.05025C6.65972 7.44077 6.02656 7.44077 5.63603 7.05025L4.92893 6.34314C4.5384 5.95262 4.5384 5.31945 4.92893 4.92893ZM12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8ZM6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12ZM2 12C2 11.4477 2.44772 11 3 11H4C4.55228 11 5 11.4477 5 12C5 12.5523 4.55228 13 4 13H3C2.44772 13 2 12.5523 2 12ZM19 12C19 11.4477 19.4477 11 20 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H20C19.4477 13 19 12.5523 19 12ZM5.63603 16.9497C6.02656 16.5592 6.65972 16.5592 7.05025 16.9497C7.44077 17.3403 7.44077 17.9734 7.05025 18.364L6.34314 19.0711C5.95262 19.4616 5.31945 19.4616 4.92893 19.0711C4.5384 18.6805 4.5384 18.0474 4.92893 17.6568L5.63603 16.9497ZM16.9498 18.364C16.5593 17.9734 16.5593 17.3403 16.9498 16.9497C17.3403 16.5592 17.9735 16.5592 18.364 16.9497L19.0711 17.6568C19.4616 18.0474 19.4616 18.6805 19.0711 19.0711C18.6806 19.4616 18.0474 19.4616 17.6569 19.0711L16.9498 18.364ZM12 19C12.5523 19 13 19.4477 13 20V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V20C11 19.4477 11.4477 19 12 19Z"
      ></path>
    </svg>
  );
}

function WaterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="28"
      width="28"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M12.6508 2.24073C12.2763 1.91974 11.7233 1.92007 11.3488 2.24106L11.2604 2.31823C11.2055 2.36654 11.1266 2.4368 11.0269 2.52728C10.8277 2.70819 10.5454 2.97036 10.2077 3.30004C9.5335 3.9582 8.63218 4.89144 7.72807 5.98928C6.8264 7.08416 5.90543 8.36263 5.206 9.71154C4.51106 11.0518 4 12.5279 4 14C4 18.4183 7.58172 22 12 22C16.4183 22 20 18.4183 20 14C20 12.5279 19.4889 11.0518 18.794 9.71154C18.0946 8.36263 17.1736 7.08416 16.2719 5.98928C15.3678 4.89144 14.4665 3.9582 13.7923 3.30004C13.4546 2.97036 13.1723 2.70819 12.9731 2.52728L12.6508 2.24073ZM6 14C6 12.9721 6.36394 11.8232 6.9815 10.6322C7.59457 9.44984 8.4236 8.29081 9.27193 7.26069C10.1178 6.23354 10.9665 5.35427 11.6048 4.73119C11.7483 4.59109 11.8809 4.46425 12 4.35191C12.1191 4.46425 12.2517 4.59109 12.3952 4.73119C13.0335 5.35427 13.8822 6.23354 14.7281 7.26069C15.5764 8.29081 16.4054 9.44984 17.0185 10.6322C17.6361 11.8232 18 12.9721 18 14C18 17.3137 15.3137 20 12 20C8.68629 20 6 17.3137 6 14Z"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M8.35964 14.0416C7.8303 14.1991 7.52889 14.7559 7.68643 15.2853C8.11874 16.7378 9.26213 17.8812 10.7147 18.3135C11.244 18.4711 11.8009 18.1697 11.9584 17.6403C12.1159 17.111 11.8145 16.5542 11.2852 16.3966C10.48 16.157 9.84298 15.52 9.60334 14.7148C9.4458 14.1854 8.88897 13.884 8.35964 14.0416Z"
      ></path>
    </svg>
  );
}

function HumidIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="28"
      width="28"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M11 2C7.68629 2 5 4.68629 5 8C5 8.13781 5.00466 8.27465 5.01385 8.41034C3.24073 9.1787 2 10.9436 2 13C2 14.4935 2.65607 15.835 3.69244 16.7497C4.10651 17.1152 4.73845 17.0758 5.10391 16.6617C5.46938 16.2477 5.42998 15.6157 5.01591 15.2503C4.39144 14.6991 4 13.8959 4 13C4 11.571 5.00015 10.3732 6.33958 10.0727C6.8783 9.95187 7.21716 9.41729 7.09656 8.87852C7.03348 8.59674 7 8.30288 7 8C7 5.79086 8.79086 4 11 4C12.9343 4 14.5499 5.37395 14.9201 7.19971C15.0161 7.67312 15.4368 8.01024 15.9198 8.00079C15.9465 8.00026 15.9732 8 16 8C18.2091 8 20 9.79086 20 12C20 13.3078 19.3734 14.469 18.3998 15.2005C17.9583 15.5323 17.8693 16.1591 18.201 16.6007C18.5327 17.0422 19.1596 17.1312 19.6012 16.7995C21.0559 15.7065 22 13.9634 22 12C22 8.91321 19.669 6.37085 16.6713 6.03713C15.858 3.68745 13.627 2 11 2ZM12.9487 15.3163C13.1233 14.7923 12.8402 14.226 12.3162 14.0513C11.7923 13.8767 11.226 14.1599 11.0513 14.6838L9.05131 20.6838C8.87667 21.2077 9.15983 21.7741 9.68377 21.9487C10.2077 22.1234 10.774 21.8402 10.9487 21.3163L12.9487 15.3163ZM16.3162 13.0513C16.8402 13.226 17.1233 13.7923 16.9487 14.3163L14.9487 20.3163C14.774 20.8402 14.2077 21.1234 13.6838 20.9487C13.1598 20.7741 12.8767 20.2077 13.0513 19.6838L15.0513 13.6838C15.226 13.1599 15.7923 12.8767 16.3162 13.0513ZM9.44868 14.3163C9.62333 13.7923 9.34017 13.226 8.81622 13.0513C8.29228 12.8767 7.72596 13.1599 7.55131 13.6838L5.55131 19.6838C5.37667 20.2077 5.65983 20.7741 6.18377 20.9487C6.70771 21.1234 7.27403 20.8402 7.44868 20.3163L9.44868 14.3163Z"
      ></path>
    </svg>
  );
}

function RepotIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="28"
      width="28"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M18 4C18 3.44772 17.5523 3 17 3C16.4477 3 16 3.44772 16 4V11C16 13.2091 14.2091 15 12 15C9.79086 15 8 13.2091 8 11L8 4C8 3.44772 7.55229 3 7 3C6.44772 3 6 3.44772 6 4V11C6 14.3137 8.68629 17 12 17C15.3137 17 18 14.3137 18 11V4ZM7 19C6.44772 19 6 19.4477 6 20C6 20.5523 6.44772 21 7 21H17C17.5523 21 18 20.5523 18 20C18 19.4477 17.5523 19 17 19H7Z"
      ></path>
    </svg>
  );
}

function PropogateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="28"
      width="28"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8C11 7.44772 11.4477 7 12 7Z"
      ></path>
    </svg>
  );
}

function PruneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="28"
      width="28"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM7 12C7 11.4477 7.44772 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H8C7.44772 13 7 12.5523 7 12Z"
      ></path>
    </svg>
  );
}

function FertilizeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="28"
      width="28"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M17 2C16.4477 2 16 2.44772 16 3C16 3.55228 16.4477 4 17 4L18.5858 4L14.2929 8.29289C13.9024 8.68342 13.9024 9.31658 14.2929 9.70711C14.6834 10.0976 15.3166 10.0976 15.7071 9.70711L20 5.41422L20 7.00001C20 7.55229 20.4477 8 21 8C21.5523 8 22 7.55229 22 7L22 3C22 2.44772 21.5523 2 21 2H17ZM4 18.5858L4 17C4 16.4477 3.55228 16 3 16C2.44771 16 2 16.4477 2 17L2 21C2 21.5523 2.44772 22 3 22H7C7.55228 22 8 21.5523 8 21C8 20.4477 7.55229 20 7 20H5.41421L9.70711 15.7071C10.0976 15.3166 10.0976 14.6834 9.70711 14.2929C9.31658 13.9024 8.68342 13.9024 8.29289 14.2929L4 18.5858Z"
      ></path>
    </svg>
  );
}

function PestIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="28"
      width="28"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M18.8061 12.9187C19.1275 12.853 19.4348 12.7487 19.7231 12.6109C21.0698 11.9671 22 10.5922 22 9C22 6.79086 20.2092 5 18 5C16.8153 5 15.7498 5.51635 15.0188 6.33309C14.6505 6.74461 14.6855 7.37681 15.097 7.74513C15.5085 8.11346 16.1407 8.07844 16.5091 7.66691C16.8767 7.25616 17.4078 7.00001 18 7C18.069 7 18.1373 7.0035 18.2045 7.01033C18.4734 7.03764 18.7266 7.11823 18.9533 7.24139C19.5768 7.58007 20 8.24061 20 9C20 9.82843 19.4963 10.5392 18.7785 10.8428C18.5392 10.944 18.2762 11 18.0001 11H18H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H18H18C18.2762 13 18.5458 12.972 18.8061 12.9187ZM5 10H10.5H10.5L10.5162 9.99995C10.5969 9.99944 10.6768 9.99509 10.7556 9.98709C12.0163 9.85908 13 8.79442 13 7.5C13 6.11929 11.8807 5 10.5 5C9.75951 5 9.09317 5.32323 8.63683 5.83309C8.26851 6.24461 8.30353 6.87681 8.71505 7.24513C9.12658 7.61346 9.75878 7.57844 10.1271 7.16691C10.2201 7.06303 10.352 7.00001 10.5 7C10.7761 7 11 7.22386 11 7.5C11 7.77614 10.7762 7.99999 10.5 8H10.5H5C4.44772 8 4 8.44772 4 9C4 9.55228 4.44772 10 5 10ZM16.5 14L8 14C7.44771 14 7 14.4477 7 15C7 15.5523 7.44771 16 8 16L16.5 16C16.7762 16 17 16.2239 17 16.5C17 16.7761 16.7761 17 16.5 17C16.352 17 16.2201 16.937 16.1271 16.8331C15.7588 16.4216 15.1266 16.3865 14.7151 16.7549C14.3035 17.1232 14.2685 17.7554 14.6368 18.1669C15.0932 18.6768 15.7595 19 16.5 19C17.8807 19 19 17.8807 19 16.5C19 15.2056 18.0163 14.1409 16.7556 14.0129C16.6768 14.0049 16.5969 14.0006 16.5162 14.0001L16.5 14Z"
      ></path>
    </svg>
  );
}
